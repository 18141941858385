export const operatorsByContinent = {
    america:
    {
        idContinent: "america",
        nameContinent: "where-we-are_america",
        countrys: [
            {
                idCountry:"argentina",
                nameCountry: "where-we-are_america-argentina",
                imageCountry: "https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-argentina.jpg",
                altImage: "Argentina flag",
                operators: [
                    {
                        operatorImage: "https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-movistar.jpg",
                        operatorImageAlt: "Movistar"
                    }
                ]
            },
            {
                idCountry:"bolivia",
                nameCountry:"where-we-are_america-bolivia",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-bolivia.jpg",
                altImage: "Bolivia flag",
                operators: [
                    {
                        operatorImage: "https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-viva.jpg",
                        operatorImageAlt: "Viva"
                    }
                ]
            },
            {
                idCountry:"canada",
                nameCountry:"where-we-are_america-canada",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-canada.jpg",
                altImage:"Canada flag", 
                operators:[
                    {
                        operatorImage: "https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-telus.jpg",
                        operatorImageAlt: "Telus"
                    }
                ]
            },
            {
                idCountry:"chile",
                nameCountry:"where-we-are_america-chile",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-chile.jpg",
                altImage:"Chile flag",
                operators: [
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-movistar.jpg",
                        operatorImageAlt:"Movistar"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-claro.jpg",
                        operatorImageAlt:"Claro"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-wom.jpg",
                        operatorImageAlt:"Wom"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-entel.jpg",
                        operatorImageAlt:"Entel"
                    }
                ]
            },
            {
                idCountry:"ecuador",
                nameCountry:"where-we-are_america-ecuador",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-ecuador.jpg",
                altImage:"Ecuador flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-claro.jpg",
                        operatorImageAlt:"Claro"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-cnt.jpg",
                        operatorImageAlt:"Cnt"
                    },
                ]
            },
            {
                idCountry:"elSalvador",
                nameCountry:"where-we-are_america-el-salvador",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-el-salvador.jpg",
                altImage:"El Salvador flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-digicel.jpg",
                        operatorImageAlt:"Digicel"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-movistar.jpg",
                        operatorImageAlt:"Movistar"
                    }
                ]
            },
            {
                idCountry:"mexico",
                nameCountry:"where-we-are_america-mexico",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-mexico.jpg",
                altImage:"Mexico flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-movistar.jpg",
                        operatorImageAlt:"Movistar"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-atyt.jpg",
                        operatorImageAlt:"AT&T"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-telcel.jpg",
                        operatorImageAlt:"Telcel"
                    }
                ]
            },
            {
                idCountry:"peru",
                nameCountry:"where-we-are_america-peru",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-peru.jpg",
                altImage:"Peru flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-entel.jpg",
                        operatorImageAlt:"Entel"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-bitel.jpg",
                        operatorImageAlt:"Bitel"
                    }
                ]
            },
            {
                idCountry:"puertoRico",
                nameCountry:"where-we-are_america-puerto-rico",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-puerto-rico.jpg",
                altImage:"Puerto Rico flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-claro.jpg",
                        operatorImageAlt:"Claro"
                    }
                ]
            },
            {
                idCountry:"uruguay",
                nameCountry:"where-we-are_america-uruguay",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-uruguay.jpg",
                altImage:"Uruguay flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-antel.jpg",
                        operatorImageAlt:"Antel"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-movistar.jpg",
                        operatorImageAlt:"Movistar"
                    }
                ]
            }
        ]
    },
    africa:
    {
        idContinent: "africa",
        nameContinent: "where-we-are_africa",
        countrys: [
            {
                idCountry:"sudafrica",
                nameCountry:"where-we-are_africa-sudafrica",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-sudafrica.jpg",
                altImage:"Sudafrica flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-vodacom.jpg",
                        operatorImageAlt:"Vodacom"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-mtn.jpg",
                        operatorImageAlt:"Mtn"
                    },
                ]
            },
            {
                idCountry:"ivorycoast",
                nameCountry:"where-we-are_africa-ivorycoast",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-ivorycoast.jpg",
                altImage:"Ivory Coast flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-orange.jpg",
                        operatorImageAlt:"Orange"
                    }
                ]
            }
        ]
    },
    asia:
    {
        idContinent: "asia",
        nameContinent: "where-we-are_asia",
        countrys: [
            {
                idCountry:"iraq",
                nameCountry:"where-we-are_asia-iraq",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-iraq.jpg",
                altImage:"Iraq flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-zain.jpg",
                        operatorImageAlt:"Zain"
                    }
                ]
            },
            {
                idCountry:"kuwait",
                nameCountry:"where-we-are_asia-kuwait",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-kuwait.jpg",
                altImage:"Kuwait flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-ooredoo.jpg",
                        operatorImageAlt:"Ooredoo"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-zain.jpg",
                        operatorImageAlt:"Zain"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-stc.jpg",
                        operatorImageAlt:"Stc"
                    }
                ]
            },
            {
                idCountry:"oman",
                nameCountry:"where-we-are_asia-oman",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-oman.jpg",
                altImage:"Oman flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-ooredoo.jpg",
                        operatorImageAlt:"Ooredoo"
                    }
                ]
            },
            {
                idCountry:"saudiArabia",
                nameCountry:"where-we-are_asia-saudi-arabia",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-saudiarabia.jpg",
                altImage:"Saudi Arabia flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-zain.jpg",
                        operatorImageAlt:"Zain"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-stc.jpg",
                        operatorImageAlt:"Stc"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-mobily.jpg",
                        operatorImageAlt:"Mobily"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-virgin-mobile.jpg",
                        operatorImageAlt:"Virgin"
                    }
                ]
            },
            {
                idCountry:"unitedArabEmirates",
                nameCountry:"where-we-are_asia-eau",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-eau.jpg",
                altImage:"United Arab Emirates flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-du.jpg",
                        operatorImageAlt:"Du"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-e&.jpg",
                        operatorImageAlt:"e&"
                    }
                ]
            }
        ]
    },
    europa:
    {
        idContinent: "europa",
        nameContinent: "where-we-are_europa",
        countrys: [
            {
                idCountry:"portugal",
                nameCountry:"where-we-are_europa-portugal",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-portugal.jpg",
                altImage:"Portugal flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-meo.jpg",
                        operatorImageAlt:"Meo"
                    }
                ]
            },
            {
                idCountry:"georgia",
                nameCountry:"where-we-are_europa-georgia",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-georgia.jpg",
                altImage:"Georgia flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-beeline.jpg",
                        operatorImageAlt:"Beeline"
                    }
                ]
            },
            {
                idCountry:"serbia",
                nameCountry:"where-we-are_europa-serbia",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-serbia.jpg",
                altImage:"Serbia flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-mts.jpg",
                        operatorImageAlt:"Mts"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-yettel.jpg",
                        operatorImageAlt:"Yettel"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-a1.jpg",
                        operatorImageAlt:"A1"
                    },
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-globaltel.jpg",
                        operatorImageAlt:"Globaltel"
                    }
                ]
            },
            {
                idCountry:"spain",
                nameCountry:"where-we-are_europa-spain",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-spain.jpg",
                altImage:"Serbia flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-yoigo.jpg",
                        operatorImageAlt:"Mts"
                    }
                ]
            },
        ]
    },
    oceania:
    {
        idContinent: "oceania",
        nameContinent: "where-we-are_oceania",
        countrys: [
            {
                idCountry:"fiji",
                nameCountry:"where-we-are_oceania-fiji",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-fiji.jpg",
                altImage:"Fiji flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-digicel.jpg",
                        operatorImageAlt:"Digicel"
                    }
                ]
            },
            {
                idCountry:"papuaNuevaGuinea",
                nameCountry:"where-we-are_ocenia-papua-nueva-guinea",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-papua-nueva-guinea.jpg",
                altImage:"Papua Nueva Guinea flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-digicel.jpg",
                        operatorImageAlt:"Digicel"
                    }
                ]
            },
            {
                idCountry:"samoa",
                nameCountry:"where-we-are-oceania-samoa",
                imageCountry:"https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-samoa.jpg",
                altImage:"Samoa flag",
                operators:[
                    {
                        operatorImage:"https://dvdt8bns6syi8.cloudfront.net/assets/operators-icons/op-digicel.jpg",
                        operatorImageAlt:"Digicel"
                    }
                ]
            }
        ] 
    }               

}

export const allOperators = [
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-airtel.jpg',
        altImage:'Airtel'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-antel.jpg',
        altImage:'Antel'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-atyt.jpg',
        altImage:'Atyt'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-avantel.jpg',
        altImage: 'Avantel'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-bitel.jpg',
        altImage: 'Bitel'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-claro.jpg',
        altImage: 'Claro'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-digicel.jpg',
        altImage: 'Digicel'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-entel.jpg',
        altImage: 'Entel'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-meo.jpg',
        altImage: 'Meo'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-movistar.jpg',
        altImage: 'Movistar'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-nextel.jpg',
        altImage: 'Nextel'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-orange.jpg',
        altImage: 'Orange'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-tigo.jpg',
        altImage: 'Tigo'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-viettel.jpg',
        altImage: 'Viettel'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-virgin.jpg',
        altImage: 'Virgin'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-viva.jpg',
        altImage: 'Viva'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-vivo.jpg',
        altImage: 'Vivo'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-vodacom.jpg',
        altImage: 'Vodacom'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-vtr.jpg',
        altImage: 'Vtr'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/operators-logos/op-logo-yoigo.jpg',
        altImage: 'Yoigo'
    }
];

export const imagesOfSlider = [
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/slider/our-products-carrousel-1.jpg',
        altImage:'Disney'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/slider/our-products-carrousel-2.jpg',
        altImage: 'Flappy Bird'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/slider/our-products-carrousel-3.jpg',
        altImage: 'Angry Birds'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/slider/our-products-carrousel-4.jpg',
        altImage: 'Get 10 Men'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/slider/our-products-carrousel-5.jpg',
        altImage: 'Tower Power'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/slider/our-products-carrousel-6.jpg',
        altImage: 'Messi'
    },
    {
        image:'https://dvdt8bns6syi8.cloudfront.net/assets/slider/our-products-carrousel-7.jpg',
        altImage: 'Evil Wyrm'
    }
];

export const filials = [
    {
        country: 'filials_argentina',
        address: 'filials_argentina-address',
        addressBold: 'filials_argentina-bold'
    },
    {
        country: 'filials_brasil',
        address: 'filials_brasil-address',
        addressBold: 'filials_brasil-bold'
    },
    {
        country: 'filials_bolivia',
        address: 'filials_bolivia-address',
        addressBold: 'filials_bolivia-bold'
    },
    {
        country: 'filials_chile',
        address: 'filials_chile-address',
        addressBold: 'filials_chile-bold'
    },
    {
        country: 'filials_colombia',
        address: 'filials_colombia-address',
        addressBold: 'filials_colombia-bold'
    },
    {
        country: 'filials_costa-rica',
        address: 'filials_costa-rica-address',
        addressBold: 'filials_costa-rica-bold'
    },
    {
        country: 'filials_ecuador',
        address: 'filials_ecuador-address',
        addressBold: 'filials_ecuador-bold'
    },
    {
        country: 'filials_el-salvador',
        address: 'filials_el-salvador-address',
        addressBold: 'filials_el-salvador-bold'
    },
    {
        country: 'filials_europe',
        address: 'filials_europe-address',
        addressBold: 'filials_europe-bold'
    },
    {
        country: 'filials_guatemala',
        address: 'filials_guatemala-address',
        addressBold: 'filials_guatemala-bold'
    },
    {
        country: 'filials_honduras',
        address: 'filials_honduras-address',
        addressBold: 'filials_honduras-bold'
    },
    {
        country: 'filials_mexico',
        address: 'filials_mexico-address',
        addressBold: 'filials_mexico-bold'
    },
    {
        country: 'filials_nicaragua',
        address: 'filials_nicaragua-address',
        addressBold: 'filials_nicaragua-bold'
    },
    {
        country: 'filials_panama',
        address: 'filials_panama-address',
        addressBold: 'filials_panama-bold'
    },
    {
        country: 'filials_paraguay',
        address: 'filials_paraguay-address',
        addressBold: 'filials_paraguay-bold'
    },
    {
        country: 'filials_peru',
        address: 'filials_peru-address',
        addressBold: 'filials_peru-bold'
    },
    {
        country: 'filials_puerto-rico',
        address: 'filials_puerto-rico-address',
        addressBold: 'filials_puerto-rico-bold'
    },
    {
        country: 'filials_republica-dominicana',
        address: 'filials_republica-dominicana-address',
        addressBold: 'filials_republica-dominicana-bold'
    },
    {
        country: 'filials_uruguay',
        address: 'filials_uruguay-address',
        addressBold: 'filials_uruguay-bold'
    },
    {
        country: 'filials_venezuela',
        address: 'filials_venezuela-address',
        addressBold: 'filials_venezuela-bold'
    }        
    
]
