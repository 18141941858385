import { useTranslation } from "react-i18next"

const CountrysByContinents = ({countrys}) => {
    
    const { t } = useTranslation('lang');

    return (
        <div className="home_where-we-are_countrys_content_cards">
            {
                countrys.map( card => (
                    <div className="home_where-we-are_countrys_content_cards_card" key={card.idCountry} >
                        <div>
                            <span>{t(card.nameCountry)}</span>
                            <img src={card.imageCountry} alt={card.altImage} />
                        </div>
                        <div>
                            {
                                card.operators.map( op => (
                                    <img src={op.operatorImage} alt={op.operatorImageAlt} key={op.operatorImageAlt} />    
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default CountrysByContinents