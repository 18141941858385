
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCountryOfContinents } from "../../helpers/getCountrysOfContinents";


const PaymentMethods = () => {

    const { t } = useTranslation('lang');
    
    const countrys = () => {
        const countrysAndOperators = getCountryOfContinents();
        const countrysAndOperatorsWithLangSeted = countrysAndOperators.map( country => {
            return {
                ...country,
                nameCountry: t(country.nameCountry) 
            }
        }) 
        return countrysAndOperatorsWithLangSeted.sort((a, b) => a.nameCountry.localeCompare(b.nameCountry))
    }
    
    useEffect(() => {
        window.scrollTo(0,0)
    })

    return (
        <main>
            <div className="payment-methods_title titles">
                <h2>{t('payment-methods_title')}</h2>
            </div>
            <div className="payment-methods_padding">
                <p style={{fontSize:'1.2rem'}} className="text-center text-padding">{t('payment-methods_description')}</p>
                <section>
                    <h3> {t('payment-methods_carrier-biling')} </h3>
                    <p>{t('payment-methods_carrier-biling-description')} </p>
                    <table className="table-carriers">
                        <tbody>
                            <tr className="table-carriers_header-row">
                                <th>{t('country')}</th>
                                <th>{t('operator')}</th>
                            </tr>
                            {
                                countrys().map( (country, i) => (
                                    <tr className={`table-carriers_row ${!(i % 2) ? 'grey' : 'soft-grey'} `} key={country.idCountry} >
                                        <td>
                                            <img 
                                                src={country.imageCountry} 
                                                alt={country.altImage} 
                                            />
                                            <span>{t(country.nameCountry)}</span>
                                        </td>
                                        <td>
                                            {
                                                country.operators.map( operator => (
                                                    <img src={operator.operatorImage} key={operator.operatorImageAlt} alt={operator.operatorImageAlt} />
                                                ))
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </section>
                <section>
                    <h3>{t('payment-methods_credit-cards')}</h3>
                    <p> {t('payment-methods_credit-cards-description')}</p>
                    <br />
                    <img className="img-full-width" src="https://dvdt8bns6syi8.cloudfront.net/assets/credit-card.jpg" alt="Credit card" />
                </section>
                <section>
                    <h3>{t('payment-methods_debit-card')}</h3>
                    <p> {t('payment-methods_debit-card-description')}</p>
                </section>
                <section>
                    <h3>{t('payment-methods_mobile-and-wallet')}</h3>
                    <p> {t('payment-methods_mobile-and-wallet-description')}</p>
                </section>
                <section>
                    <h3>{t('payment-methods_mercado-pago')}</h3>
                    <p style={{marginBottom:0}}> {t('payment-methods_mercado-pago-description')}</p>
                    <ul>
                        <li> {t('payment-methods_mercado-pago-li1')} </li>
                        <li> {t('payment-methods_mercado-pago-li2')} </li>
                        <li> {t('payment-methods_mercado-pago-li3')} </li>
                        <li> {t('payment-methods_mercado-pago-li4')} </li>
                        <li> {t('payment-methods_mercado-pago-li5')} </li>
                    </ul>
                    <br /><br />
                    <img className="img-full-width" src="https://dvdt8bns6syi8.cloudfront.net/assets/qr-payment.jpg" alt="QR payment" />
                </section>
                <section>
                    <h3>{t('payment-methods_transfer')}</h3>
                    <p>{t('payment-methods_transfer-description')}</p>
                </section>
                <section>
                    <h3>{t('payment-methods_cash')}</h3>
                    <p>{t('payment-methods_cash-description')}</p>
                </section>
                <section>
                    <h3>{t('payment-methods_cripto')}</h3>
                    <p style={{marginBottom:0}}>{t('payment-methods_cripto-p1')}</p>
                    <p style={{marginTop:0}}>{t('payment-methods_cripto-p2')}</p>
                </section>
                <br /><br />
            </div>
        </main>
    )
}

export default PaymentMethods;