import { useTranslation } from "react-i18next";
import { useState } from "react";
import {operatorsByContinent} from "../../../references";
import CountrysByContinents from "../../../components/CountrysByContinent";

const WhereWeAre = () => {

    const { t } = useTranslation('lang');
    const [operatorsInfo] = useState(operatorsByContinent);
    const [continentOnView, setContinentOnView] = useState(operatorsInfo.america.idContinent);

    return (
        <section className="home_where-we-are" id="whereWeAre">

            <div className="home_where-we-are_title titles">
                <h2>{t('where-we-are_title')}</h2>
            </div>

            <div className="home_where-we-are_map">
                <img src="https://dvdt8bns6syi8.cloudfront.net/assets/where-we-are-map.jpg" alt="Global map" />
            </div>

            <div className="home_where-we-are_countrys">
                <div className="home_where-we-are_countrys_content">
                    <ul>
                        <li style={{ color: continentOnView === 'america' ? 'var(--lightblue)' : '' }} onClick={() => setContinentOnView('america')} >{t('where-we-are_america')}</li>
                        <li style={{ color: continentOnView === 'africa' ? 'var(--lightblue)' : '' }} onClick={() => setContinentOnView('africa')}>{t('where-we-are_africa')}</li>
                        <li style={{ color: continentOnView === 'asia' ? 'var(--lightblue)' : '' }} onClick={() => setContinentOnView('asia')}>{t('where-we-are_asia')}</li>
                        <li style={{ color: continentOnView === 'europa' ? 'var(--lightblue)' : '' }} onClick={() => setContinentOnView('europa')}>{t('where-we-are_europa')}</li>
                        <li style={{ color: continentOnView === 'oceania' ? 'var(--lightblue)' : '' }} onClick={() => setContinentOnView('oceania')}>{t('where-we-are_oceania')}</li>
                    </ul>
                    <CountrysByContinents {...operatorsInfo[continentOnView]}/>
                </div>
                <div className="home_where-we-are_countrys_next-openings">
                    <h3>{t('where-we-are-upcoming-openings')} </h3>
                    <ul>
                        <li>
                            <img src="https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-costa-rica.jpg" alt="Costa Rica flag"/>
                            <span>{t('where-we-are-upcoming-openings-costa-rica')}</span>
                        </li>
                        <li>
                            <img src="https://dvdt8bns6syi8.cloudfront.net/assets/flags/flag-europa.jpg" alt="Rest of Europe flag"/>
                            <span>{t('where-we-are-upcoming-openings-rest-europa')}</span>
                        </li>
                    </ul>
                </div>
            </div>

        </section>
    )
}

export default WhereWeAre;