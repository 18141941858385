import './App.css';
import Headsite from './components/Headsite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {Route,BrowserRouter,Routes,} from "react-router-dom";
import Home from './pages/home';
import PaymentMethods from './pages/payment-methods';

const App = () => {

  const { i18n } = useTranslation('lang');

  useEffect(() => {
    const preferredLangUser = localStorage.getItem('lang');
    if (preferredLangUser) i18n.changeLanguage(preferredLangUser).catch(err => console.log(err));
  }, [i18n])

  return (
    <BrowserRouter>
      <Headsite />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/payment-methods' element={<PaymentMethods />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
