import { useTranslation } from "react-i18next";
import { filials } from "../../../references";

const Filials = () => {

    const { t } = useTranslation('lang');

    return(
        <section className="home_filials" id="filials">
            <div className="home_filials_title titles">
                <h2>{t('filials')}</h2>
            </div>
            <div className="home_filials_cards">
                {
                    filials.map( filial => (
                        <div className="home_filials_cards_card" key={filial.country} >
                            <h3>{t(filial.country)}</h3>
                            <img src="https://dvdt8bns6syi8.cloudfront.net/assets/filials-separator.png" alt="Separator"/>
                            <span>{t(filial.address)}</span>
                            <strong>{t(filial.addressBold)}</strong>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default Filials;