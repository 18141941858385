import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Langs from "./Langs";

const Headsite = () => {

    const { t } = useTranslation('lang');
    const [openMenu, setOpenMenu] = useState(false);
    const navigate = useNavigate();

    const handleCloseMenu = () => {
        const menu = document.querySelector('.header_nav-mobile');
        const shadowContainer = document.querySelector('.header_shadow');
        shadowContainer.classList.add('fadeOut')
        menu.classList.add('slideMenuToOut');
        menu.addEventListener('animationend', () => setOpenMenu(false))
        document.querySelector('html').style.overflow = 'scroll';
    }

    const handleOpenMenu = () => {
        setOpenMenu(true);
        document.querySelector('html').style.overflow = 'hidden';
    }

    const menuItems = () => {
        return (
            <ul>
                <li><a href="/#aboutUs">{t('header_about-us')}</a></li>
                <li><a href="/#whyRenxo">{t('header_why-renxo')}</a></li>
                <li><a href="/#whereWeAre">{t('header_where-we-are')}</a></li>
                <li><a href="/#mobileOperators">{t('header_operators-mobile')}</a></li>
                <li><Link to={'/payment-methods'}> {t('payment-methods')} </Link></li>
                <li><a href="/#ourProducts">{t('header_products')}</a></li>
                <li><a href="/#filials">{t('header_filiales')}</a></li>
                <li><a href="/#contact">{t('header_contact')}</a></li>
            </ul>
        )
    }

    return (
        <header>
            <img src="https://dvdt8bns6syi8.cloudfront.net/assets/logo.svg" alt="Logo" className="header_logo" onClick={() => window.location.href = window.origin } />
            <nav className="header_nav-desktop">{menuItems()}</nav>
            <img src="https://dvdt8bns6syi8.cloudfront.net/assets/icon-menu.svg" alt="Menu" className="header_mobile-btn" onClick={handleOpenMenu} />
            <Langs />
            {
                openMenu &&
                <div className="header_nav-mobile">
                    <nav>{menuItems()}</nav>
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/logo.svg" alt="logo" className="top_bar_menu-container_logo-menu" />
                </div>
            }
            {openMenu && <div className="header_shadow" onClick={handleCloseMenu}></div>}
        </header>
    )
}

export default Headsite;