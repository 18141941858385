import { useTranslation } from "react-i18next";
import { useState } from "react";
import {allOperators} from "../../../references";

const MobileOperators = () => {

    const {t} = useTranslation('lang');
    const [operators] = useState(allOperators);

    return(
        <section className="home_mobile-operators" id="mobileOperators">
            
            <div className="home_mobile-operators_title titles">
                <h2>{t('mobile-operators_title')}</h2>
            </div>

            <div className="home_mobile-operators_operators">
                {operators.map( (operator, i) => <img key={i} alt={operator.altImage} src={operator.image} className="home_mobile-operators_operators_operator"/>)}
            </div>
        
        </section>
    )
}

export default MobileOperators;