import { useTranslation } from "react-i18next";

const AboutUs = () => {

    const { t } = useTranslation('lang');

    return (
        <section className="home_about-us" id="aboutUs">

            <div className="home_about-us_info">

                <div className="home_about-us_info_item">
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/about-us-1.png" alt="About us" />
                    <hr />
                    <h2>{t('about-us_about-us')}</h2>
                    <p>{t('about-us_about-us-p')}</p>
                </div>

                <div className="home_about-us_info_item">
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/about-us-2.png" alt="What we do" />
                    <hr />
                    <h2>{t('about-us_what-we-do')} </h2>
                    <p>{t('about-us_what-we-do-p')}</p>
                </div>

                <div className="home_about-us_info_item">
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/about-us-3.png" alt="Team" />
                    <hr />
                    <h2>{t('about-us_team')}</h2>
                    <p>{t('about-us_team-p')}</p>
                </div>

            </div>

            <hr/>

            <div className="home_about-us_info-2">

                <div>
                    <h2 style={{color:'#8BC800'}}>{t('about-us_expansion')}</h2>
                    <p>{t('about-us_expansion-p')} </p>
                </div>

                <img src="https://dvdt8bns6syi8.cloudfront.net/assets/about-us-4.png" alt="Gear" />

                <div>
                    <h2 style={{color:'#FF4B3A'}}>{t('about-us_premium-content')}</h2>
                    <p>{t('about-us_premium-content-p')}</p>
                </div>

                <div>
                    <h2 style={{color:'#00ADC7'}} >{t('about-us_ads')}</h2>
                    <p>{t('about-us_ads-p')}</p>
                </div>

            </div>

        </section>
    )
}

export default AboutUs;