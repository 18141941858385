
import { t } from "i18next";
import { operatorsByContinent } from "../references";

export const getCountryOfContinents = () => {
    const countrysOfAmerica = operatorsByContinent.america.countrys;
    const countrysOfAfrica = operatorsByContinent.africa.countrys;
    const countrysOfAsia = operatorsByContinent.asia.countrys;
    const countrysOfEuropa = operatorsByContinent.europa.countrys;
    const countrysOfOceania = operatorsByContinent.oceania.countrys;
    const countrysJoined = [
        ...countrysOfAfrica,
        ...countrysOfAmerica,
        ...countrysOfAsia,
        ...countrysOfEuropa,
        ...countrysOfOceania
    ];
    return countrysJoined;
}