
import AboutUs from "./components/AboutUs";
import WhyRenxo from "./components/WhyRenxo";
import WhereWeAre from "./components/WhereWeAre";
import OurProducts from "./components/OurProducts";
import Filials from "./components/Filials";
import Contact from "./components/Contact";
import MobileOperators from "./components/MobileOperatos";

const Home = () => {
    return (
        <main>
            <video src='https://dvdt8bns6syi8.cloudfront.net/assets/front-page-video.mp4' className='home_front-video' autoPlay loop muted></video>
            <AboutUs />
            <WhyRenxo />
            <WhereWeAre />
            <MobileOperators />
            <OurProducts />
            <Filials />
            <Contact />
        </main>
    )
}

export default Home;