import { useTranslation } from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { useState } from "react";

const Contact = () => {

    const { t } = useTranslation('lang');
    const reCaptchaRef = useRef();

    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        message: ''
    });
    const { name, email, message } = formValues;

    const statusErrorInitialState = { show: false, msg: '', color: '' };
    const [showStatusErrorOfForm, setShowStatusErrorOfForm] = useState(statusErrorInitialState);

    const handleChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const captchaToken = reCaptchaRef.current.getValue();
        const regExpEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const errorStatus = { show: true, msg: t('contact_form-error-message'), color: 'red' };
        if ( !captchaToken || !name.trim() || !email.trim() || !message.trim() || !regExpEmail.test(email) ){
            setShowStatusErrorOfForm(errorStatus);
            return setTimeout( () => setShowStatusErrorOfForm(statusErrorInitialState), 3000);
        }  
        const body = new FormData();
        body.append('your-name', name);
        body.append('your-email', email);
        body.append('your-message', message);
        try {
            await fetch( window.origin + '/envio.php', {
                body,
                method: 'POST'
            })
            setShowStatusErrorOfForm({ show: true, msg: t('contact_form-successful-msg'), color: 'green' })
            setTimeout(() => setShowStatusErrorOfForm(statusErrorInitialState), 3000)
        } catch (error) {
            console.log(error);
            setShowStatusErrorOfForm(errorStatus);
            setTimeout(() => setShowStatusErrorOfForm(statusErrorInitialState), 3000)
        }
    }

    return (
        <section className="home_contact" id="contact">
            <h2>{t('contact')}</h2>
            <span>{t('contact-p')}</span>
            <form autoComplete="off" onSubmit={handleSubmit} >
                <div>
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/contact-form-name.png" alt="Name" />
                    <input
                        type="text"
                        name="name"
                        placeholder={t('contact_form-name')}
                        required
                        value={name}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/contact-form-email.png" alt="Email" />
                    <input
                        type="email"
                        name="email"
                        placeholder={t('contact_form-email')}
                        required
                        value={email}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/contact-form-message.png" alt="Message" />
                    <textarea
                        name="message"
                        placeholder={t('contact_form-message')}
                        required
                        value={message}
                        onChange={handleChange}

                    ></textarea>
                </div>
                <ReCAPTCHA
                    sitekey={'6LcGAwMhAAAAALa2drvxlqJe0HK7WBmkgvNdvS_8'}
                    ref={reCaptchaRef}
                    className="captcha"
                />
                <button>{t('contact_form-btn-send')}</button>
                {showStatusErrorOfForm.show && <strong style={{ color: showStatusErrorOfForm.color }} >{showStatusErrorOfForm.msg}</strong>}
            </form>
        </section>
    )
}

export default Contact;