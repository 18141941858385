import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { imagesOfSlider } from "../../../references";

const OurProducts = () => {

    const { t } = useTranslation('lang');
    const slider1 = useRef();
    const slider2 = useRef();

    return (
        <section className="home_our-products" id="ourProducts">

            <div className="home_our-products_title titles">
                {t('our-products_title')}
            </div>

            <div className="home_our-products_contents">
                <h3>{t('our-products_contents')}</h3>
                <p>{t('our-products_contents-p')}</p>
                <img src="https://dvdt8bns6syi8.cloudfront.net/assets/our-products-1.png" alt="Contents" />
            </div>

            <img src="https://dvdt8bns6syi8.cloudfront.net/assets/our-products-2.png" alt="1200 Games and apps" />

            <div className="home_our-products_slider">

                <Slider
                    className='home_our-products_slider_1'
                    ref={slider1}
                    asNavFor={slider2.current}
                    autoplay={true}
                >
                    {imagesOfSlider.map(image => <img key={image.altImage} src={image.image} alt={image.altImage} />)}
                </Slider>

                <Slider
                    className="home_our-products_slider_2"
                    ref={slider2}
                    asNavFor={slider1.current}
                    slidesToShow={4}
                    autoplay={true}
                >
                    {imagesOfSlider.map(image => <img key={image.altImage} src={image.image} alt={image.altImage} />)}
                </Slider>

                <div className="home_our-products_slider_so-image">
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/our-products-3.png" alt="Operatives systems and technologies" />
                </div>

            </div>

            <div className="home_our-products_content-store">
                <h3>{t('our-products_store-contents')}</h3>
                <div className="home_our-products_content-store_list">
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/our-products-4.png" alt="Store of contents"/>
                    <ul>
                        <li><img src="https://dvdt8bns6syi8.cloudfront.net/assets/tick.png" alt="Tick"/> {t('our-products_store-contents-li1')}</li>
                        <li><img src="https://dvdt8bns6syi8.cloudfront.net/assets/tick.png" alt="Tick"/> {t('our-products_store-contents-li2')}</li>
                        <li><img src="https://dvdt8bns6syi8.cloudfront.net/assets/tick.png" alt="Tick"/> {t('our-products_store-contents-li3')}</li>
                        <li><img src="https://dvdt8bns6syi8.cloudfront.net/assets/tick.png" alt="Tick"/> {t('our-products_store-contents-li4')}</li>
                        <li><img src="https://dvdt8bns6syi8.cloudfront.net/assets/tick.png" alt="Tick"/> {t('our-products_store-contents-li5')}</li>
                        <li><img src="https://dvdt8bns6syi8.cloudfront.net/assets/tick.png" alt="Tick"/> {t('our-products_store-contents-li6')}</li>
                        <li><img src="https://dvdt8bns6syi8.cloudfront.net/assets/tick.png" alt="Tick"/> {t('our-products_store-contents-li7')}</li>
                        <li><img src="https://dvdt8bns6syi8.cloudfront.net/assets/tick.png" alt="Tick"/> {t('our-products_store-contents-li8')}</li>
                    </ul>
                </div>
                <img src="https://dvdt8bns6syi8.cloudfront.net/assets/our-products-5.png" alt="Operatives systems and technologies"/>
            </div>

            <div className="home_our-products_suscriptions">
                <h3>{t('our-products_suscriptions')}</h3>
                <p>{t('our-products_suscriptions-p')}</p>
                <div className="home_our-products_suscriptions_catalog">
                    <div>
                        <h4>{t('our-products_catalog')}</h4>
                        <a href="#.">{t('our-products_catalog-a')}</a>
                    </div>
                </div>
            </div>

            <div className="home_our-products_some-products">
                
                <div className="home_our_products_some-products-product">
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/our-products-6_new.png" alt="Toonz"/>
                    <div>
                        <h3>{t('our-products_toonz')}</h3>
                        <p>{t('our-products_toonz-p')}</p>
                    </div>
                </div>

                <div className="home_our_products_some-products-product">
                    <div>
                        <h3>{t('our-products_xone')}</h3>
                        <p>{t('our-products_xone-p')}</p>
                        <img src="https://dvdt8bns6syi8.cloudfront.net/assets/our-products-8.png" alt="Xone"/>
                    </div>
                    <img src="https://dvdt8bns6syi8.cloudfront.net/assets/our-products-7.png" alt="Xone"/>
                </div>
            
            </div>

        </section>
    )
}

export default OurProducts;