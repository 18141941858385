
import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";

const Langs = () => {
    
    const { i18n } = useTranslation('lang');
    const [openLangs, setOpenLangs] = useState(false);
    const languages = ['en','es','pt'];

    const langsMenuRef = useRef();
    const handleOpenLangs = () => {
        if (openLangs) {
            langsMenuRef.current.classList.add('closeLangMenu');
            return langsMenuRef.current.addEventListener('animationend', () => setOpenLangs(false))
        }
        setOpenLangs(true)
    }

    const handleChangeLenguage = (lang) => {
        i18n.changeLanguage(lang).then( () => localStorage.setItem('lang', lang) ).catch( err => console.log(err));
    }

    return (
        <div className="header_langs">
            <span onClick={handleOpenLangs}>{i18n.language.toUpperCase()}</span>
            <img
                onClick={handleOpenLangs}
                src="https://dvdt8bns6syi8.cloudfront.net/assets/arrow.png"
                alt="Arrow"
                className={`header_langs_arrow ${!openLangs ? 'rotate-arrow-to-top' : 'rotate-arrow-to-down'}`}
            />
            {
                openLangs &&
                <ul ref={langsMenuRef} >
                    {
                        languages.map( language => {
                            if(language !== i18n.language){
                                return(
                                    <li onClick={() => handleChangeLenguage(language)} key={language} >{language.toUpperCase()}</li>
                                )
                            }else{
                                return null
                            }
                        })
                    }
                </ul>
            }
        </div>
    )
}

export default Langs;