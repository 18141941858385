import { useTranslation } from "react-i18next";

const WhyRenxo = () => {

    const { t } = useTranslation('lang');

    return(
        <section className="home_why-renxo" id="whyRenxo">
            
            <div className="home_why-renxo_title titles">
                <h2>{t('why-renxo_title')}</h2>
            </div>

            <div className="home_why-renxo_content">
                <img src="https://dvdt8bns6syi8.cloudfront.net/assets/why-renxo-inversion.png" alt="Inverson capacity"/>
                <div className="home_why-renxo_content_info">
                    <h3>{t('why-renxo_inversion')}</h3>
                    <p>{t('why-renxo_inversion-p')}</p>
                    <a id="partner" href="https://www.google.com/partners/agency?id=6580521930" target="_blank" rel="noreferrer">
                        <img src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg" alt="Google partner"/>
                    </a>
                </div>
            </div>
            
            <div className="home_why-renxo_content">
                <div className="home_why-renxo_content_info">
                    <h3>{t('why-renxo_experiencie')} </h3>
                    <p>{t('why-renxo_experiencie-p')}</p>
                </div>
                <img src="https://dvdt8bns6syi8.cloudfront.net/assets/why-renxo-experience.png" alt="Experience"/>
            </div>
           
            <div className="home_why-renxo_content">
                <img src="https://dvdt8bns6syi8.cloudfront.net/assets/why-renxo-license.png" alt="License"/>
                <div className="home_why-renxo_content_info">
                    <h3>{t('why-renxo_licenses')}</h3>
                    <p>{t('why-renxo_licenses-p')}</p>
                </div>
            </div>
            
            <div className="home_why-renxo_content">
                <div className="home_why-renxo_content_info">
                    <h3>{t('why-renxo_certification')}</h3>
                    <p>{t('why-renxo_certification-p')}</p>
                </div>
                <img src="https://dvdt8bns6syi8.cloudfront.net/assets/why-renxo-iso.jpg" alt="ISO"/>
            </div>

        </section>
    )
}

export default WhyRenxo;